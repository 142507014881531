










































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.item {
  min-width: 200px;
  border: 1px solid #d8d8d8;
  height: 300px;
  .item-ovy-a {
    overflow-y: auto;
  }
  h3 {
    padding: 10px;
    border-bottom: 1px dashed #717171;
    text-align: center;
  }
  > div {
    padding: 10px 15px;
  }
}
.borderL0 {
  border-left: 0;
}
